<template>
  <div class="time-box" @mousemove="boxMousemove">
    <div class="time-chs">
      <div class="time-chs-it" v-for="it in rows" :key="it.name">
        <div class="time-chs-it-name">{{ it.name }}</div>
        <div
          class="time-chs-it-dot"
          v-for="(ik, ix) in it.times"
          :key="it.name + '_ch_' + ix"
          :style="{ left: ik.left + '%', width: ik.width + '%' }"
          @dblclick="clickCH(ik)"
        ></div>
      </div>
    </div>
    <div class="time-line">
      <div class="time-line-box">
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
        <div class="time-line-box-it"></div>
      </div>
      <div class="time-line-txt">
        <div class="time-line-txt-it time-line-txt-it-first">00:00</div>
        <div class="time-line-txt-it">02:00</div>
        <div class="time-line-txt-it">04:00</div>
        <div class="time-line-txt-it">06:00</div>
        <div class="time-line-txt-it">08:00</div>
        <div class="time-line-txt-it">10:00</div>
        <div class="time-line-txt-it">12:00</div>
        <div class="time-line-txt-it">14:00</div>
        <div class="time-line-txt-it">16:00</div>
        <div class="time-line-txt-it">18:00</div>
        <div class="time-line-txt-it">20:00</div>
        <div class="time-line-txt-it">
          22:00
          <div class="time-line-txt-it-last">24:00</div>
        </div>
      </div>
    </div>
    <div class="time-tl" :style="{ left: timeLeft - 2 + 'px' }"></div>
    <div class="time-tp" :style="{ left: timeLeft + 2 + 'px' }">
      {{ timeTip }}
    </div>
  </div>
</template>

<script>
import { it } from "element-plus/es/locale";
export default {
  emits: ["timeEvent"],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isLoadEvent: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    data(val) {
      let ay = val;
      let ay_ay = [];
      let times;
      let l1, l2;
      ay.forEach((it) => {
        times = [];
        it.times.forEach((ik) => {
          l1 = (this.str2int(ik.start_time) / 86400) * 100;
          l2 = (this.str2int(ik.end_time) / 86400) * 100;
          times.push({
            start_time: ik.start_time,
            end_time: ik.end_time,
            left: l1,
            width: Math.abs(l2 - l1),
            ch: ik.ch,
          });
        });
        ay_ay.push({ name: it.name, times: times });
      });
      this.rows = ay_ay;
      // console.log("wid");
      // let obj = document.querySelector(".time-chs");
      // let w = this.getStyle(obj, "width");
      // // console.log("wid", w);
      // this.$nextTick(() => {
      //   let box = document.querySelector(".time-box");
      //   let tl = document.querySelector(".time-tl");
      //   let tp = document.querySelector(".time-tp");
      //   this.boxOffset = this.getOffsetSum(box);
      //   console.log("boxOffset", this.boxOffset);
      //   box.addEventListener("mouseover", (evt) => {
      //     console.log("mouseover", evt, this.boxOffset.left);
      //     this.$nextTick(() => {
      //       tl.style.left = evt.pageX - this.boxOffset.left + "px";
      //       tp.style.left = evt.pageX + 1 - this.boxOffset.left + "px";
      //     });
      //   });
      // });
    },
    isLoadEvent(val) {
      // this.$nextTick(() => {
      // let obj = document.querySelector(".time-chs");
      // let w = this.getStyle(obj, "width");
      let box = document.querySelector(".time-box");
      this.boxWidth = box.offsetWidth - 2;
      // console.log("w", box.offsetWidth, w);
      this.boxOffset = this.getOffsetSum(box);
      // let tl = document.querySelector(".time-tl");
      // let tp = document.querySelector(".time-tp");

      // console.log("boxOffset", this.boxOffset);
      // box.addEventListener("mouseover", (evt) => {
      //   // console.log("mouseover", evt, this.boxOffset.left);
      //   tl.style.left = evt.pageX - this.boxOffset.left + "px";
      //   tp.style.left = evt.pageX + 1 - this.boxOffset.left + "px";
      // });
      // });
    },
  },
  mounted() {
    // const containerRect = this.$el.getBoundingClientRect();
    // console.log("aaaa", this.$el, containerRect.left);
    // let box = document.querySelector(".time-box");
    // let tl = document.querySelector(".time-tl");
    // this.boxOffset = this.getOffsetSum(box);
  },

  data() {
    return {
      timeTip: "00:00:00",
      timeLeft: 0,
      boxWidth: 1,
      boxOffset: {},
      rows: [],
    };
  },
  methods: {
    boxMousemove(evt) {
      // console.log("boxMousemove", evt);
      this.timeLeft = evt.pageX - this.boxOffset.left;
      // console.log(this.timeLeft, this.boxWidth);
      if (this.timeLeft < 0) {
        this.timeLeft = 0;
      } else if (this.timeLeft >= this.boxWidth) {
        this.timeLeft = this.boxWidth;
      }
      let timeNum = (this.timeLeft * 86400) / this.boxWidth;

      this.timeTip = this.int2time(timeNum);
      // console.log(this.timeTip);
    },
    getPageOffset(element) {
      var rect = element.getBoundingClientRect();
      var docElem = document.documentElement;
      var offsetX = rect.left + window.pageXOffset - docElem.clientLeft;
      var offsetY = rect.top + window.pageYOffset - docElem.clientTop;

      return {
        offsetX: offsetX,
        offsetY: offsetY,
      };
    },
    getOffsetSum(ele) {
      var top = 0,
        left = 0;
      while (ele) {
        top += ele.offsetTop;
        left += ele.offsetLeft;
        ele = ele.offsetParent;
      }
      return {
        top: top,
        left: left,
      };
    },
    getStyle(obj, attr) {
      return obj.currentStyle
        ? obj.currentStyle[attr]
        : getComputedStyle(obj, 1)[attr];
    },
    int2time(seconds) {
      if (
        seconds == "" ||
        seconds == null ||
        seconds == undefined ||
        seconds < 0
      ) {
        return "00:00:00";
      }
      var time = parseInt(seconds);
      var hour = Math.floor(time / 3600);
      var hours = Math.floor(seconds / 3600);
      var minutes = Math.floor((seconds % 3600) / 60);
      var remainingSeconds = parseInt(seconds % 60);

      return (
        this.addLeadingZero(hours) +
        ":" +
        this.addLeadingZero(minutes) +
        ":" +
        this.addLeadingZero(remainingSeconds)
      );
    },

    addLeadingZero(number) {
      return (number < 10 ? "0" : "") + number;
    },
    str2int(str) {
      let rt = 0;
      let ay = str.split(":");
      rt = parseInt(ay[0]) * 3600 + parseInt(ay[1]) * 60 + parseInt(ay[2]);
      return rt;
    },
    clickCH(item) {
      console.log("item");
      this.$emit("timeEvent", Object.assign(item, { clickTime: this.timeTip }));
    },
  },
};
</script>

<style lang="scss" scoped>
.time-box {
  width: 100%;
  height: calc(100% - 40px);
  box-sizing: border-box;
}
.time-line {
  width: 100%;
  height: 40px;
}
.time-chs {
  width: 100%;
  height: 100px;
  overflow-y: auto;
}

.time-line-box {
  box-sizing: border-box;
  border: 1px solid #d6d6d6;
  border-bottom: none;
  border-right: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
.time-line-box-it {
  box-sizing: border-box;
  width: calc(100% / 24);
  height: 20px;
  border-right: 1px solid #d6d6d6;
}
.time-line-txt {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow: hidden;
}
.time-line-txt-it {
  box-sizing: border-box;
  width: calc(100% / 12);
  height: 20px;
  line-height: 20px;
  text-indent: -20px;
  position: relative;
}
.time-line-txt-it-first {
  text-indent: 0;
}
.time-line-txt-it-last {
  position: absolute;
  top: 0;
  right: 0;
}
.time-chs-it {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 20px;
  line-height: 20px;
  border-bottom: #d6d6d6 1px dotted;
}
.time-chs-it-dot {
  position: absolute;
  // background: #0f0;
  background: #3669a5;
  width: 100px;
  height: 19px;
  left: 0;
  z-index: 99;
}
.time-chs-it-name {
  position: absolute;
  left: 200px;
  line-height: 20px;
  z-index: 100;
}
.time-tl {
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0px;
  z-index: 1001;
  background-color: #0071c6;
  color: #0071c6;
}
.time-tp {
  left: 1px;
  top: 40px;
  height: 18px;
  position: absolute;
  background-color: #0071c6;
  z-index: 1001;
  color: #fff;
  padding: 2px 10px 2px 2px;
}
</style>
